import {
  memo, useEffect, useState,
} from 'react';
import {
  Input, Modal, Select, Table, Tag,
} from 'antd';
import winesApiSlice from '@State/wines';
import { useDebouncedCallback } from 'use-debounce';
import { produttoriApiSlice } from '@State/produttori/api';

const { useLazyFetchWinesQuery, useLazyGetWineImageQuery } = winesApiSlice;
const { useFetchProduttoriQuery } = produttoriApiSlice;

const { Option } = Select;

const columns = [
  {
    title: 'Vino',
    dataIndex: 'descrizione',
  },
  {
    title: 'Produttore',
    dataIndex: 'produttore',
  },
  {
    title: 'Denominazione',
    dataIndex: 'denominazione',
  },
  {
    title: 'Regione',
    dataIndex: 'regione',
  },
  {
    title: 'Vitigni',
    dataIndex: 'vitigni',
    render: (_, { vitigni }) => (
      <>
        {vitigni.map((vitigno) => (
          <>
            {vitigno}

            <br />
          </>
        ))}
      </>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (status) => {
      const color = status === 'PENDING'
        ? 'geekblue'
        : status === 'APPROVED'
          ? 'green'
          : 'volcano';

      const text = status === 'PENDING'
        ? 'IN ATTESA'
        : status === 'APPROVED'
          ? 'APPROVATO'
          : 'RIFIUTATO';

      return (
        <Tag color={color}>
          {text.toUpperCase()}
        </Tag>
      );
    },
  },
  {
    title: 'Inserito da',
    dataIndex: 'inseritoDa',
  },
];

function BackOffice() {
  const [trigger, { data = [], isUninitialized, isLoading }] = useLazyFetchWinesQuery();
  const [triggerGetImage, { data: imageData, isLoading: isLoadingImage, error }] = useLazyGetWineImageQuery();
  const { data: produttori = [] } = useFetchProduttoriQuery();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contextConfiguration, setContextConfiguration] = useState({
    pageSize: 20,
    current: 1,
    search: '',
    produttore: '',
  });

  useEffect(() => {
    trigger(contextConfiguration);
  }, [contextConfiguration, trigger]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showImage = async (uid) => {
    await triggerGetImage({ uid });
    showModal();
  };

  const onRow = ({ uid }) => ({
    onClick: () => showImage(uid),
  });

  const handleOnChange = ({ pageSize, current }, b, c, d) => {
    console.debug(b, c, d);
    setContextConfiguration({
      ...contextConfiguration,
      pageSize,
      current,
    });
  };

  const debouncedSetFilter = useDebouncedCallback(
    ({ target: { value: search } }) => {
      setContextConfiguration({
        ...contextConfiguration,
        search,
        current: 1,
      });
    },
    250,
  );

  const onChange = (value) => {
    setContextConfiguration({
      ...contextConfiguration,
      produttore: value || '',
    });
  };

  return (
    <div className="flex flex-col p-4 gap-4">
      <div className="flex flex-col gap-4 w-72">
        <Input onChange={debouncedSetFilter} placeholder="Cerca..." />

        <Select
          allowClear
          filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
          onChange={onChange}
          optionFilterProp="children"
          placeholder="Filtra per produttore"
          showSearch
        >
          {(produttori.body || []).map(({ _id, descrizione }) => <Option key={_id} value={descrizione}>{descrizione}</Option>)}
        </Select>
      </div>

      <Table
        columns={columns}
        dataSource={data.body}
        loading={isUninitialized || isLoading || isLoadingImage}
        onChange={handleOnChange}
        onRow={onRow}
        pagination={{
          current: contextConfiguration.current,
          pageSize: contextConfiguration.pageSize,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          total: data?.headers?.xTotalCount,
        }}
        rowClassName="cursor-pointer"
        rowKey="uid"
        sticky
      />

      <Modal cancelText="Reject" okText="Approve" onCancel={handleCancel} onOk={handleOk} open={isModalOpen}>
        <div className="flex flex-row justify-center">
          {!error && <img alt="wine-pic" src={imageData} />}

          {error && <p>Immagine non trovata</p>}
        </div>
      </Modal>
    </div>
  );
}

export default memo(BackOffice);
