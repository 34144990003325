import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

let app = null;

const firebaseConfig = {
  apiKey: 'AIzaSyAnWrQYNk9k_U2HCHj4wIyWkI_8CHk_iN0',
  authDomain: 'winefriends-7017a.firebaseapp.com',
  projectId: 'winefriends-7017a',
  storageBucket: 'winefriends-7017a.appspot.com',
  messagingSenderId: '202340257201',
  appId: '1:202340257201:web:eb38b0b576e727063f0190',
  measurementId: 'G-CW364VPZVY',
};

const useInitializeFirebase = () => {
  if (app) {
    return;
  }

  app = initializeApp(firebaseConfig);

  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LfiNMYlAAAAAJ2TfsjTGuuedonD2Rjcf6m0OBou'),
    isTokenAutoRefreshEnabled: true,
  });
};

export default useInitializeFirebase;
