import { endpoint, wineFriendsApi } from '@Store/apis';
import { API_TAGS, AREAS } from '@Src/constants';

export const produttoriApiSlice = wineFriendsApi.injectEndpoints({
  endpoints: (builder) => ({

    fetchProduttori: builder.query({
      query: () => ({
        baseUrl: endpoint(AREAS.PRODUTTORI),
        url: '/',
        method: 'get',
      }),
      providesTags: [API_TAGS.PRODUTTORI],
    }),

  }),
});
