import {
  memo, useEffect, useState,
} from 'react';
import useInitializeFirebase from '@Src/hooks/useInitializeFirebase';
import {
  Routes, Route,
} from 'react-router-dom';
import BackOffice from '@Containers/BackOffice';
import Login from '@Containers/Login';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

function App() {
  const [user, setUser] = useState(undefined);
  useInitializeFirebase();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (mUser) => {
      if (mUser) {
        setUser(mUser);
      }
    });
  }, []);

  return (
    <Routes>
      <Route
        element={user ? <BackOffice /> : <Login />}
        path="/*"
      />
    </Routes>
  );
}

export default memo(App);
