const API_TAGS = {
  PRODUTTORI: 'PRODUTTORI',
  WINES: 'WINES',
};

const AREAS = {
  PRODUTTORI: 'produttori',
  STATIC: 'static',
  WINES: 'wines',
};

export {
  API_TAGS,
  AREAS,
};
