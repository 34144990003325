import { wineFriendsApi } from '@Store/apis';
import {
  getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence,
} from 'firebase/auth';

export const authApiSlice = wineFriendsApi.injectEndpoints({
  endpoints: (builder) => ({

    login: builder.mutation({
      queryFn: async ({ email, password }) => {
        try {
          const auth = getAuth();
          await setPersistence(auth, browserSessionPersistence);
          await signInWithEmailAndPassword(auth, email, password);
          return { data: undefined };
        } catch (e) {
          console.error(e);
          return { error: e };
        }
      },
    }),

  }),
});
