import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { API_TAGS } from '@Src/constants';

export const endpoint = (area) => `${process.env.DOMAIN}/${area}`;

const queryWithToken = async ({
  baseUrl, url, method, data, headers = {}, responseType,
}) => {
  try {
    const token = await getAuth().currentUser.getIdToken(true);
    const headersWithAuth = { Authorization: token, ...headers };
    const result = await axios({
      url: `${baseUrl}${url}`,
      method,
      data,
      headers: headersWithAuth,
      responseType,
    });

    return {
      data: {
        body: result.data,
        headers: {
          ...result.headers,
          xTotalCount: result.headers['x-total-count'] !== undefined ? +result.headers['x-total-count'] : undefined,
        },
      },
    };
  } catch (axiosError) {
    console.error(axiosError);
    const err = axiosError;

    return {
      error: { status: err.response?.status, data: err.response?.data },
    };
  }
};

export const wineFriendsApi = createApi({
  reducerPath: 'wineFriendsApi',
  baseQuery: queryWithToken,
  tagTypes: Object.values(API_TAGS),
  endpoints: () => ({}),
});
