import { endpoint, wineFriendsApi } from '@Store/apis';
import { API_TAGS, AREAS } from '@Src/constants';
import { Buffer } from 'buffer';

export const winesApiSlice = wineFriendsApi.injectEndpoints({
  endpoints: (builder) => ({

    fetchWines: builder.query({
      query: ({
        current, pageSize, search, produttore,
      }) => ({
        baseUrl: endpoint(AREAS.WINES),
        url: `/?size=${pageSize}&page=${current}&search=${search}&produttore=${produttore}`,
        method: 'get',
      }),
      providesTags: [API_TAGS.WINES],
    }),

    getWineImage: builder.query({
      query: ({ uid }) => ({
        baseUrl: endpoint(AREAS.STATIC),
        url: `/images/wines/${uid}.png`,
        method: 'get',
        responseType: 'arraybuffer',
      }),
      transformResponse: (response) => `data:${response.headers['content-type']};base64,${Buffer.from(response.body, 'binary').toString('base64')}`,
    }),

  }),
});
