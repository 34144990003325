import { configureStore } from '@reduxjs/toolkit';
import { wineFriendsApi } from '@Store/apis';

const mainReducer = {
  [wineFriendsApi.reducerPath]: wineFriendsApi.reducer,
};

const middleware = (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
  .concat(wineFriendsApi.middleware);

export const store = configureStore({
  reducer: mainReducer,
  middleware,
  devTools: { name: 'wine-friends' },
});
