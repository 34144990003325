import { memo } from 'react';
import {
  Button, Form, Input, Spin,
} from 'antd';
import authApiSlice from '@State/auth';

const { useLoginMutation } = authApiSlice;

function Login() {
  const [triggerLogin, { isLoading }] = useLoginMutation();

  const onFinish = (values) => {
    triggerLogin(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        autoComplete="off"
        labelCol={{ span: 8 }}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{
          maxWidth: 600,
        }}
        wrapperCol={{
          span: 16,
        }}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button htmlType="submit" type="primary">
            Login
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
}

export default memo(Login);
